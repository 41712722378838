:root {
  --iteration-delay: 0.15s;
  --size: 32px;
  --bg: #111;
  --bgr: 0;
  --bgg: 0;
  --bgb: 0;
  --fg: #fff;
  --fgr: 255;
  --fgg: 255;
  --fgb: 255;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background: var(--bg);
  color: var(--fg);
  font-family: system-ui, sans-serif;
}

a {
  color: var(--fg);
}

hr {
  width: 90%;
  border-top: none;
  border-bottom: 1px solid color(from var(--fg) srgb 0.8 0.8 0.8);
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  overflow-y: hidden;
}

a:focus-visible,
input:focus-visible,
button:focus-visible {
  outline: color(from var(--fg) srgb 0.8 0 b / 100%) solid 3px;
  z-index: 1;
  outline-offset: 4px;
  border-radius: 2px;
}

header {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

footer.timeline {
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  z-index: 10;
}

footer.config {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  z-index: 10;
}

footer.config button[disabled] {
  opacity: 0.5;
}

footer input[type="number"] {
  max-width: 50px;
}

.boards {
  position: relative;
  transform-style: preserve-3d;
}

.board {
  width: max-content;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
  transition: all 0.3s;
  z-index: 1;
  border: 1px solid rgb(var(--fgr) var(--fgg) var(--fgb) / 15%);

  /* makes animating easier */
  position: absolute;
  top: 0;
  left: 0;

  /* we will need to maintain this offset */
  transform: translateX(-50%) translateY(-50%);
  transform-style: preserve-3d;

  &.iso {
    transform: translateX(-50%) translateY(-50%) rotateX(60deg) rotateY(0deg) rotateZ(-45deg) !important;

    button.alive {
      border: none !important;

      &::before {
        border: 1px solid color(from var(--fg) srgb r g b / 65%);
      }

      &::after {
        border: 1px solid color(from var(--fg) srgb r g b / 65%);
      }
    }
  }

  button {
    border: none;
    width: var(--size);
    height: var(--size);
    transition: all calc(var(--iteration-delay) * 0.6);
    background: transparent;
    transform-style: preserve-3d;
    z-index: 0;

    &:focus-visible {
      outline: color(from var(--fg) srgb 0.8 0 b / 100%) solid 3px;
      z-index: 1;
      outline-offset: 2px;
    }

    &.alive {
      background: color(from var(--fg) srgb r g b / 100%);
    }
  }
}

.showing-history {
  &.boards {
    display: flex;
    max-width: 100dvw;
    padding-right: calc(9rem * var(--count));

    --translateX: 25%;
    --perspective: 1000px;
    --rotationAmount: calc(20deg / var(--count));
  }

  .board {
    transition: all 0.1s;
    /*transition: all 0s;*/

    /* can't have filter, otherwise preserve-3d breaks */

    /* filter: drop-shadow(5px 11px 10px rgba(0, 0, 0, 0.15)); */
    left: calc(7rem * var(--index) - 30rem);
    transform:
      /* centering offset */ translateY(-50%)
      translateX(calc(-50% + (2rem * var(--index)))) perspective(var(--perspective))
      rotateY(calc(40deg * (var(--index) / var(--count))));

    &:hover {
      /* opacity: 1; */
      z-index: 20;
      transform:
        /* centering offset */ translateY(-50%) translateZ(500px)
        translateX(calc(-50% + (2rem * var(--index)))) scale(1.2);
    }

    &.historical {
      transition: all 0.3s;
    }
  }
}

.boards {
  &.show-lines {
    .board {
      button {
        border: 1px solid color(from var(--fg) srgb r g b / 15%);
        background: var(--bg);

        &.alive {
          background: rgb(var(--fgr) var(--fgg) var(--fgb));
          border: 1px solid color(from var(--bg) srgb r g b / 20%);
        }
      }

      &.historical {
        button {
          background: color(from var(--bg) srgb r g b / 85%);

          &.alive {
            background: color(from var(--fg) srgb r g b / 85%);
          }
        }
      }
    }
  }
}

.board {
  button {
    &.alive {
      --d: 1.5rem;

      transform: translateZ(var(--d));

      &::before,
      &::after {
        content: "";
        bottom: 0;
        left: 0;
        position: absolute;
        border: inherit;
      }

      &::before {
        width: var(--d);
        background: color(from var(--fg) srgb 0.9 0.8 0.8);

        /* background: rgba(50,50,50,1); */
        top: 0;
        transform-origin: left;
        transform: rotateY(90deg);
      }

      &::after {
        height: var(--d);
        background: color(from var(--fg) srgb 0.7 0.7 0.9);

        /* background: rgba(30,30,30,1); */
        right: 0;
        transform-origin: bottom;
        transform: rotateX(90deg);
      }
    }
  }

  &.historical {
    button {
      background: color(from var(--bg) srgb r g b / 90%);
    }

    button.alive {
      background: color(from var(--fg) srgb r g b / 90%);

      /* background: rgba(0,0,0,0.9); */

      &::after {
        background: color(from var(--fg) srgb 0.7 0.7 0.9);
      }

      &::before {
        background: color(from var(--fg) srgb 0.9 0.8 0.8);
      }
    }
  }
}
